import DatasetTabsViewer from 'src/pages/commons/plan-views/DatasetTabsViewer';
import PlanInputRowValidationGrid from 'src/pages/plan-manager-page/plan-input-tab/PlanInputRowValidationGrid';
import PlanOutputDataGrid from 'src/pages/plan-manager-page/plan-output-tab/components/PlanOutputDataGrid';
import {
  getOutputSubTabs,
  getOutputDatasetOptions,
} from 'src/pages/plan-manager-page/plan-output-tab/planOutputConfig';
import { SupportedDatasetType } from 'src/utils/planning/planetModel';

export const TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES = new Set([
  SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST,
  SupportedDatasetType.TOPS_DOWN_FORECAST_MM_FORECAST,
  SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT,
]);

export const FIXED_COST_CONSOLIDATION_EDITABLE_OUTPUT_DATASET_TYPES = new Set([
  SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_OUTPUT,
]);

const EDITABLE_OUTPUT_DATASET_TYPES = new Set([
  ...TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES,
  ...FIXED_COST_CONSOLIDATION_EDITABLE_OUTPUT_DATASET_TYPES,
]);

const PlanOutputTab = () => (
  <DatasetTabsViewer
    getSubTabs={getOutputSubTabs}
    getDatasetOptions={getOutputDatasetOptions}
    renderGridComponent={(o) => {
      if (EDITABLE_OUTPUT_DATASET_TYPES.has(o.currentDataset))
        return <PlanInputRowValidationGrid {...o} key={o.currentDataset} />;
      return <PlanOutputDataGrid {...o} key={o.currentDataset} />;
    }}
  />
);

export default PlanOutputTab;
