import { Alert, Button, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import {
  SourceLocator,
  UploadBatchEditsRequest,
  ValidationStatusEnumEnum,
} from '@amzn/fox-den-cost-planning-lambda';
import { useQueryClient } from '@tanstack/react-query';
import {
  BaseExportParams,
  EditableCallback,
  ProcessHeaderForExportParams,
} from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiExport, BiImport, BiRefresh } from 'react-icons/bi';
import { BsFillArchiveFill } from 'react-icons/bs';
import { FaRegClone, FaRegSave } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { RiImportFill, RiExportFill } from 'react-icons/ri';
import { useBlocker, useParams } from 'react-router';
import { find } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { OperationType, useUploadEditsMutation } from 'src/api/mutation/useUploadEditsMutation';
import { QUERY_KEYS, RequestVariables } from 'src/api/api-utils';
import {
  useBatchBusinessRules,
  useBatchUpsertDatasets,
  useStaticFilterList,
} from 'src/api/query/useBatchDimensionValues';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { usePlanDataset } from 'src/api/query/usePlanDataset';
import { UploadFileModal } from 'src/common/UploadFileModal';
import {
  IInlineEditsList,
  agRowInlineEditsAdd,
  agRowInlineEditsDelete,
  IInlineEditType,
} from 'src/common/ag-grid/row-validation-grid/AgRowValidationEditHelpers';
import AgRowValidationGrid from 'src/common/ag-grid/row-validation-grid/AgRowValidationGrid';
import {
  IRowValidationCellData,
  IRowValidationRowWithId,
  IValidationRowTransformer,
  getValidationRowTransformer,
  standardizeInitialData,
  getErrorRowIds,
  navigateToNextErrorRowIds,
} from 'src/common/ag-grid/row-validation-grid/AgRowValidators';
import { SelectChangeEvent } from 'src/common/EventType';
import {
  useTriggerComputeFromCPU,
  useTriggerComputeFromMM,
  useTriggerLunaExport,
  useTriggerPlanImportBaseline,
  useTriggerPlanLoadDatasets,
} from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { downloadFile, getDatasetErrorInfo } from 'src/utils/errorMessage';
import {
  SupportedDatasetType,
  PlanTypeId,
  RegionId,
  TriggerType,
} from 'src/utils/planning/planetModel';
import { currentDateTime } from 'src/utils/time';
import {
  getLastUpdatedOnOfDataset,
  getSchemaForDataset,
  getMappingSchemaForDataset,
} from 'src/utils/planning/batchMetadataModel';
import { useColumnDefs } from 'src/utils/planning/useColumnDefs';
import { useOnBeforeUnload } from 'src/hooks/useOnBeforeUnload';
import UserConfirmModal, { ConfirmModalInfo } from 'src/common/UserConfirmModal';
import { GridComponentProps } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import DatasetGridContainer, {
  DataGridActionButtonGroups,
} from 'src/pages/commons/plan-views/DatasetGridContainer';
import {
  FIXED_COST_CONSOLIDATION_EDITABLE_OUTPUT_DATASET_TYPES,
  TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES,
} from 'src/pages/plan-manager-page/plan-output-tab/PlanOutputTab';
import { generateStaticSiteList } from 'src/pages/commons/plan-filters/StaticFilterList';
import { IFilterList, useFilterComponent } from 'src/pages/commons/plan-filters/useFilterComponent';
import { buildComputeParams } from 'src/api/mutation/useTriggerComputeMutation';
import { useAsyncUploadEditsMutation } from 'src/api/mutation/useAsyncUploadEditsMutation';
import { usePlanStateContext } from 'src/pages/plan-manager-page/PlanManagerPage';
import { useIsPlanPolling } from 'src/pages/commons/compute-helpers/useComputationPollingManager';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

// TODO: remove this hardcoded, retrieve list from DimensionAPI
export const UTRLargeDatasetNames: Set<SupportedDatasetType> = new Set([
  SupportedDatasetType.PLANET_UTR_VARIABLE_BASE_CPH,
  SupportedDatasetType.PLANET_UTR_VARIABLE_LEAVE_OF_ABSENCE_PERCENTAGE,
  SupportedDatasetType.PLANET_UTR_VARIABLE_SURCHARGES,
  SupportedDatasetType.PLANET_UTR_VARIABLE_NON_HR_COST,
  SupportedDatasetType.UTR_PRODUCTIVITY_VARIABLE_PLANNED_HIRES_RELEASES_OVERRIDE,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_OVERLAYS,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_HOURS_ASSUMPTIONS,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_LEARNING_CURVES,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_BASELINE_RATES,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_VOLUME_OVERRIDE,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_STARTING_HEADCOUNT_OVERRIDE,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_HEADCOUNT_ADJUSTMENTS_GUARDRAILS,
  SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_RULE_BASED_HOURS,
]);

export const OTRAutomatedImportDatasets: Set<SupportedDatasetType> = new Set([
  SupportedDatasetType.PLANET_OTR_OB_HISTORICAL_LOADS,
  SupportedDatasetType.PLANET_OTR_OB_LDT_ACTUALS,
  SupportedDatasetType.PLANET_OTR_OB_SPEED_ACTUALS,
  SupportedDatasetType.PLANET_OTR_OB_CORRIDOR_ALLOC,
]);

interface IRowValidationGridData {
  rowData: any;
  validHeaders: string[];
  validationRowTransformer: IValidationRowTransformer;
}

const PlanInputRowValidationGrid = ({
  currentDataset,
  setCurrentDataset,
  datasetOptions,
  headerText,
  description,
}: GridComponentProps) => {
  const { t } = useTranslation();

  const { data: featureFlags } = useFeatureFlags();

  const [inlineEdits, setInlineEdits] = useState<IInlineEditsList>([]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const [tableData, setTableData] = useState<IRowValidationGridData | null>(null);

  const [isImportModalVisible, setIsImportModalVisible] = useState(false);

  const [isUpdatingDataset, setIsUpdatingDataset] = useState(false);

  const [shouldTriggerDatasetUpdate, setShouldTriggerDatasetUpdate] = useState(false);

  const [confirmModalInfo, setConfirmModalInfo] = useState<ConfirmModalInfo>(null);

  /**
   * error rows that are saved to server and with unresolved errors
   * - include uploaded dataset or manually saved data
   * - not include errors that are not saved to server yet
   */
  const [savedUnsolvedErrorRowIds, setSavedUnsolvedErrorRowIds] = useState<Set<number>>(new Set());

  const {
    isHasUnsavedEdits,
    setIsHasUnsavedEdits,
    trackingAsyncUploadDatasets,
    setTrackingAsyncUploadDatasets,
  } = usePlanStateContext();

  const queryClient = useQueryClient();

  const uniqueId = useRef(1);

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { processingComputeTrigger } = useIsPlanPolling(batchMetadata);

  const gridRef = useRef<AgGridReact>(null);

  const isLargeDataset = UTRLargeDatasetNames.has(currentDataset);

  const isFixedCostConsolidationEditableOutputDataset =
    FIXED_COST_CONSOLIDATION_EDITABLE_OUTPUT_DATASET_TYPES.has(currentDataset);

  const inlineEditsDisabled =
    isLargeDataset ||
    batchMetadata?.status === 'CLOSED' ||
    processingComputeTrigger === TriggerType.TOPS_DOWN_FORECAST_VOLUME_DATASET_IMPORT ||
    processingComputeTrigger === TriggerType.TOPS_DOWN_FORECAST_BASELINE_CPU_DATASET_IMPORT;

  const isColumnEditable = useMemo(() => {
    if (inlineEditsDisabled) return false;

    if (isFixedCostConsolidationEditableOutputDataset) {
      const rowChecker: EditableCallback = (params) => {
        const appliedMetricsValue = params.data.applied_metrics?.value?.toLowerCase();
        return !(
          appliedMetricsValue?.includes('assembly') || appliedMetricsValue?.includes('baseline')
        );
      };
      return rowChecker;
    }

    return true;
  }, [inlineEditsDisabled, isFixedCostConsolidationEditableOutputDataset]);

  const planRequiresSiteFilter = isLargeDataset;

  const { data: sitesFilterList, isError: noSitesFilterList } = useStaticFilterList(
    {
      planType: batchMetadata?.costType ?? '',
      subGroup: batchMetadata?.subGroup ?? '',
      region: batchMetadata?.region ?? '',
    },
    { disabled: !batchMetadata || !planRequiresSiteFilter },
  );

  const filterConfig = useMemo(() => {
    const filterInfo: IFilterList = [];
    if (planRequiresSiteFilter) {
      filterInfo.push({
        name: 'site',
        label: 'site_name_filter_label',
        options: noSitesFilterList ? generateStaticSiteList() : sitesFilterList ?? [],
      });
    }

    return filterInfo;
  }, [noSitesFilterList, planRequiresSiteFilter, sitesFilterList]);

  const { filtersValid, filterMap, FilterComponent } = useFilterComponent(filterConfig);

  const { data: upsertDatasets } = useBatchUpsertDatasets(
    { planType: (batchMetadata?.costType as PlanTypeId) ?? '' },
    { disabled: !batchId },
  );

  const fileUploadOperation = useMemo(() => {
    if (upsertDatasets == null) {
      console.log(`No upsert datasets in DimensionValues DDB for ${batchMetadata?.costType}.`);
    }

    return upsertDatasets?.includes(currentDataset) ? OperationType.UPSERT : OperationType.REPLACE;
  }, [batchMetadata?.costType, currentDataset, upsertDatasets]);

  const schema = useMemo(
    () => getSchemaForDataset(currentDataset, batchMetadata),
    [batchMetadata, currentDataset],
  );

  const mappingSchema = useMemo(
    () => getMappingSchemaForDataset(currentDataset, batchMetadata),
    [batchMetadata, currentDataset],
  );

  const getNewRowId = () => uniqueId.current++;

  const { columnDefs } = useColumnDefs(schema, mappingSchema);

  const { triggerLoadDatasets } = useTriggerPlanLoadDatasets({
    planType: batchMetadata?.costType,
    isLargeDataset,
  });

  const { mutate: asyncUploadEdits } = useAsyncUploadEditsMutation({
    onSuccess: (data, variables) => {
      const newTrackingDatasets = new Set(variables.datasetNames as SupportedDatasetType[]);
      setTrackingAsyncUploadDatasets(trackingAsyncUploadDatasets.union(newTrackingDatasets));
    },
  });

  const { triggerImportBaseline } = useTriggerPlanImportBaseline();

  const { triggerComputeFromCPU } = useTriggerComputeFromCPU();

  const { triggerComputeFromMM } = useTriggerComputeFromMM();

  const { triggerLunaExport } = useTriggerLunaExport();

  const { mutate: updateNormalDatasetMutation } = useUploadEditsMutation({
    onMutate: () => {
      setIsUpdatingDataset(true);
    },
    onError: () => {
      setIsUpdatingDataset(false);
    },
    onSuccess: () => {
      const onSuccess = () => setInlineEdits([]);
      const onSettled = () => setIsUpdatingDataset(false);

      if (batchMetadata?.batchId) {
        switch (currentDataset) {
          case SupportedDatasetType.TOPS_DOWN_FORECAST_COEFFICIENT:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_CHC:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_Q2G_CPU:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_VOLUME:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT:
          case SupportedDatasetType.TOPS_DOWN_FORECAST_MANUAL_BRIDGE:
          case SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_FIXED:
          case SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_VARIABLE:
          case SupportedDatasetType.CF_CONSOLIDATION_INPUT_SHIPPED_UNITS:
          case SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_INPUT:
          case SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_NON_DESIGNATED_INPUT:
          case SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_OUTPUT:
            onSuccess();
            onSettled();
            break;
          case SupportedDatasetType.TOPS_DOWN_FORECAST_CPU_FORECAST:
            triggerComputeFromCPU({ batchId: batchMetadata.batchId }, { onSuccess, onSettled });
            break;
          case SupportedDatasetType.TOPS_DOWN_FORECAST_MM_FORECAST:
            triggerComputeFromMM({ batchId: batchMetadata.batchId }, { onSuccess, onSettled });
            break;
          default:
            if (featureFlags?.feLoadDatasetByAsyncUploadAditsApi) {
              asyncUploadEdits(
                { batchId: batchMetadata.batchId, datasetNames: [currentDataset] },
                { onSuccess, onSettled },
              );
            } else {
              triggerLoadDatasets({ batchId: batchMetadata.batchId }, { onSuccess, onSettled });
            }
            break;
        }
      }
    },
  });

  /**
   * Literally does nothing except for changing the lastUpdatedTime of the currentDataset
   * due to bug in BE which doesn't update lastUpdatedTime in UTR_Large_Data_Update_Load
   */
  const { mutate: updateLargeDatasetMutation } = useUploadEditsMutation({
    onMutate: () => {
      setIsUpdatingDataset(true);
    },
    onError: () => {
      setIsUpdatingDataset(false);
    },
    onSuccess: (data, variables) => {
      const onSuccess = () => setInlineEdits([]);
      const onSettled = () => setIsUpdatingDataset(false);

      if (batchMetadata?.batchId) {
        if (featureFlags?.feLoadDatasetByAsyncUploadAditsApi) {
          asyncUploadEdits(
            {
              batchId: batchMetadata.batchId ?? '',
              datasetNames: [variables.datasetType],
              parameters: buildComputeParams({
                batchEditsFile: variables.batchEditsFile,
                headers: variables.headers,
                partitionS3VersionMap: variables.partitionS3VersionMap,
                partitionLocator: variables.partitionLocator,
                operation: variables.operation,
              }),
            },
            { onSuccess, onSettled },
          );
        } else {
          triggerLoadDatasets(
            {
              batchId: batchMetadata.batchId ?? '',
              parameters: buildComputeParams({
                batchEditsFile: variables.batchEditsFile,
                headers: variables.headers,
                datasetType: variables.datasetType,
                // lastUpdatedOn,
                partitionS3VersionMap: variables.partitionS3VersionMap,
                partitionLocator: variables.partitionLocator,
                operation: variables.operation,
                datasetName: variables.datasetType,
              }),
            },
            { onSuccess, onSettled },
          );
        }
      }
    },
  });

  const validDatasetLocation = useMemo(
    () => !!find(batchMetadata?.datasetLocations, { datasetName: currentDataset }),
    [batchMetadata?.datasetLocations, currentDataset],
  );

  const { data: rawData, isFetching: planDatasetIsFetching } = usePlanDataset(
    {
      datasetName: currentDataset,
      batchId: batchMetadata?.batchId ?? '',
      filterList: filterMap,
    },
    schema ?? [],
    {
      disabled: !batchMetadata || !batchMetadata.batchId || !validDatasetLocation || !filtersValid,
      cacheTime: 0,
      staleTime: Infinity,
    },
  );

  const { data: businessRules, isFetching: businessRulesIsFetching } = useBatchBusinessRules(
    {
      datasetName: currentDataset,
      planType: batchMetadata?.costType as PlanTypeId,
      region: batchMetadata?.region as RegionId,
    },
    { disabled: !batchMetadata },
  );

  useEffect(() => {
    setIsHasUnsavedEdits(inlineEdits.length > 0);
  }, [inlineEdits.length, setIsHasUnsavedEdits]);

  /** generate table data using data fetched from BE  */
  useEffect(() => {
    if (!rawData || !businessRules || !columnDefs) return;

    const validHeaders = schema?.map((item) => item.dimensionName) ?? [];

    const validationRowTransformer = getValidationRowTransformer(
      businessRules,
      columnDefs,
      schema,
      featureFlags?.feUseColumnMappingSchemaConfig ?? false,
      featureFlags?.usingValidationFramework ?? false,
    );

    if (!validationRowTransformer) return;

    const newRowData = standardizeInitialData(
      rawData.dataset,
      validationRowTransformer,
      uniqueId,
      featureFlags?.usingValidationFramework,
    );

    setInlineEdits([]);

    setTableData({ rowData: newRowData, validationRowTransformer, validHeaders });

    /** initialize savedUnsolvedErrorRowIds */
    const errorRowIds = getErrorRowIds(newRowData);
    setSavedUnsolvedErrorRowIds(errorRowIds);

    /** navigate to the first error row */
    setTimeout(() => {
      navigateToNextErrorRowIds(gridRef, errorRowIds);
    }, 300);
  }, [rawData, businessRules, columnDefs, schema, featureFlags?.feUseColumnMappingSchemaConfig]);

  const handleClickExport = () => {
    gridRef.current?.api.exportDataAsExcel();
  };

  const currentDatasetLabel = datasetOptions.find((item) => item.value === currentDataset)?.label;

  const clearGrid = () => {
    gridRef?.current?.api?.showLoadingOverlay?.();
    setTableData(null);
    setInlineEdits([]);
  };

  // Clear Grid when filters Change
  useEffect(() => {
    if (filtersValid && filterMap) clearGrid();
  }, [filterMap, filtersValid]);

  //Clear Grid when dataset changes
  const handleChangeSelectWithConfirmation = (e: SelectChangeEvent) => {
    const newDataset = e.detail.selectedOption.value as SupportedDatasetType;

    if (newDataset === currentDataset) return;

    if (isHasUnsavedEdits) {
      setConfirmModalInfo({
        header: t('discard_changes'),
        message: t('change_tables_unsaved_changes_warning'),
        onProceed: () => {
          setCurrentDataset(newDataset);
          clearGrid();
        },
      });
    } else {
      setCurrentDataset(newDataset);
      clearGrid();
    }
  };

  const handleUploadEdits = (fileLocator: SourceLocator) => {
    if (!rawData || !batchMetadata || !tableData) return;

    const { partitionS3VersionMap, partitionLocator } = rawData.metadata;
    const lastUpdatedOn = getLastUpdatedOnOfDataset(batchMetadata, currentDataset);

    const fileUploadArguments: RequestVariables<UploadBatchEditsRequest> = {
      batchId: batchMetadata.batchId ?? '',
      batchEditsFile: fileLocator,
      headers: tableData.validHeaders,
      datasetType: currentDataset,
      lastUpdatedOn,
      partitionS3VersionMap: partitionS3VersionMap ?? null,
      partitionLocator: partitionLocator ?? null,
      operation: fileUploadOperation,
    };

    if (isLargeDataset) {
      updateLargeDatasetMutation(fileUploadArguments);
    } else {
      updateNormalDatasetMutation(fileUploadArguments);
    }
  };

  const handleSaveDataset = useCallback(() => {
    if (!rawData || !batchMetadata || !tableData || inlineEditsDisabled) {
      setIsUpdatingDataset(false);
      return;
    }

    const batchEdits = inlineEdits.map((item) => {
      switch (item.editType) {
        case IInlineEditType.ADD:
          return { previous: [], current: item.current };
        case IInlineEditType.MODIFY:
          return { previous: item.previous, current: item.current };
        case IInlineEditType.DELETE:
          return { previous: item.previous, current: [] };
      }
    });

    const { partitionS3VersionMap, partitionLocator } = rawData.metadata;
    const lastUpdatedOn = getLastUpdatedOnOfDataset(batchMetadata, currentDataset);

    const inlineEditsUploadArguments: RequestVariables<UploadBatchEditsRequest> = {
      batchId: batchMetadata.batchId ?? '',
      batchEdits,
      headers: tableData.validHeaders,
      datasetType: currentDataset,
      lastUpdatedOn,
      partitionS3VersionMap: partitionS3VersionMap ?? null,
      partitionLocator: partitionLocator ?? null,
      operation: OperationType.UPSERT,
    };

    updateNormalDatasetMutation(inlineEditsUploadArguments);
  }, [
    batchMetadata,
    currentDataset,
    inlineEdits,
    inlineEditsDisabled,
    rawData,
    tableData,
    updateNormalDatasetMutation,
  ]);

  useEffect(() => {
    if (shouldTriggerDatasetUpdate) {
      setShouldTriggerDatasetUpdate(false);
      handleSaveDataset();
    }
  }, [handleSaveDataset, shouldTriggerDatasetUpdate]);

  const actionButtonGroups = useMemo<DataGridActionButtonGroups>(() => {
    const isTopsDownEditableOutputDataset =
      TOPS_DOWN_EDITABLE_OUTPUT_DATASET_TYPES.has(currentDataset);

    const addRow = () => {
      const gridApi = gridRef.current?.api;
      if (
        !gridApi ||
        !tableData?.validationRowTransformer ||
        !tableData?.validHeaders ||
        tableData.validHeaders.length === 0
      ) {
        return;
      }

      const rowID = getNewRowId();

      const newRow: IRowValidationRowWithId = {
        id: {
          value: rowID,
          original: rowID,
          lastValidationSuccess: true,
          ...(featureFlags?.usingValidationFramework && {
            lastValidationNoWarning: true,
          }),
          validationErrors: [],
        },
      };

      for (const header of tableData.validHeaders) {
        const newValue: IRowValidationCellData = {
          value: null,
          original: null,
          lastValidationSuccess: true,
          ...(featureFlags?.usingValidationFramework && {
            lastValidationNoWarning: true,
          }),
          validationErrors: [],
        };

        newRow[header] = newValue;
      }

      const formattedRow = tableData.validationRowTransformer(newRow);

      const inlineEditsUpdater = agRowInlineEditsAdd(formattedRow, tableData.validHeaders);
      setInlineEdits(inlineEditsUpdater);

      gridApi.applyTransactionAsync({ add: [formattedRow], addIndex: 0 }, (resultNodes) => {
        gridApi.paginationGoToFirstPage();
        gridApi.ensureIndexVisible(0);
        // workaround to make flashCells work for newly created cells
        setTimeout(() => {
          gridApi.flashCells({ rowNodes: resultNodes?.add });
        }, 300);
      });
    };

    const handleCloneRows = () => {
      const gridApi = gridRef.current?.api;

      if (
        !gridApi ||
        !tableData?.validationRowTransformer ||
        !tableData?.validHeaders ||
        tableData.validHeaders.length === 0
      ) {
        return;
      }

      const selectedRows = gridApi.getSelectedRows();

      if (selectedRows.length === 0) return;

      const rowsToAdd = selectedRows.map((row: IRowValidationRowWithId, idx: number) => {
        const record: IRowValidationRowWithId = {
          id: {
            value: idx + uniqueId.current,
            original: idx + uniqueId.current,
            lastValidationSuccess: true,
            ...(featureFlags?.usingValidationFramework && {
              lastValidationNoWarning: true,
            }),
            validationErrors: [],
          },
        };

        for (const [key, cellData] of Object.entries(row)) {
          if (key !== 'id') {
            const newValue: IRowValidationCellData = {
              value: cellData.value,
              original: null,
              lastValidationSuccess: true,
              ...(featureFlags?.usingValidationFramework && {
                lastValidationNoWarning: true,
              }),
              validationErrors: [],
            };

            record[key] = newValue;
          }
        }
        return tableData.validationRowTransformer(record);
      });

      uniqueId.current += selectedRows.length;

      for (const row of rowsToAdd) {
        const inlineEditsUpdater = agRowInlineEditsAdd(row, tableData.validHeaders);
        setInlineEdits(inlineEditsUpdater);
      }

      gridApi.applyTransactionAsync({ add: rowsToAdd, addIndex: 0 }, (resultNodes) => {
        gridApi.paginationGoToFirstPage();
        gridApi.ensureIndexVisible(0);
        gridApi.deselectAll();
        // workaround to make flashCells work for newly created cells
        setTimeout(() => {
          gridApi.flashCells({ rowNodes: resultNodes?.add });
        }, 300);
      });
    };

    const handleDeleteRows = () => {
      const gridApi = gridRef.current?.api;
      if (!tableData || !gridApi) {
        return;
      }

      const selectedRows = gridApi.getSelectedRows();

      for (const row of selectedRows) {
        const inlineEditsUpdater = agRowInlineEditsDelete(row, tableData.validHeaders);
        setInlineEdits(inlineEditsUpdater);
      }

      gridApi.applyTransaction({ remove: selectedRows });
    };

    const expandDatasetView = () => {
      setIsFullScreen(true);
    };

    const reloadData = () => {
      queryClient.resetQueries({
        queryKey: [QUERY_KEYS.PLAN_DATASET, batchId, currentDataset],
      });
      clearGrid();
    };

    const reloadDataWithConfirmation = () => {
      if (isHasUnsavedEdits) {
        setConfirmModalInfo({
          header: t('discard_changes'),
          message: t('refresh_data_unsaved_changes_warning'),
          onProceed: reloadData,
        });
      } else {
        reloadData();
      }
    };

    const showImportModal = () => {
      setIsImportModalVisible(true);
    };

    const showImportModalWithConfirmation = () => {
      if (isHasUnsavedEdits) {
        setConfirmModalInfo({
          header: t('discard_changes'),
          message: t('import_unsaved_changes_warning'),
          onProceed: showImportModal,
        });
      } else {
        showImportModal();
      }
    };

    const handleWebbImport = () => {
      if (!batchMetadata?.batchId) return;
      triggerImportBaseline({ batchId: batchMetadata.batchId });
    };

    const handleLunaExport = () => {
      if (!batchMetadata?.batchId) return;
      triggerLunaExport({ batchId: batchMetadata.batchId });
    };

    const handleSaveButtonClick = () => {
      setIsUpdatingDataset(true);
      /** Give time for ag grid to commit any cells currently being edited
       *  and inlineEdits to reflect the new value **/
      setTimeout(() => setShouldTriggerDatasetUpdate(true), 500);
    };

    return [
      [
        {
          icon: FaRegSave,
          text: t('save'),
          onClick: handleSaveButtonClick,
          disabled: isUpdatingDataset,
          hide: inlineEditsDisabled,
        },
        {
          icon: RiImportFill,
          text: t('webb_import'),
          onClick: handleWebbImport,
          disabled: isUpdatingDataset,
          hide: currentDataset !== SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE,
        },
        {
          icon: BiImport,
          text: t('import'),
          onClick: showImportModalWithConfirmation,
          disabled: isUpdatingDataset,
          hide: isTopsDownEditableOutputDataset,
        },
        {
          icon: RiExportFill,
          text: t('luna_export'),
          onClick: handleLunaExport,
          disabled: isUpdatingDataset,
          hide: currentDataset !== SupportedDatasetType.TOPS_DOWN_FORECAST_LUNA_OUTPUT,
        },
        {
          icon: BiExport,
          text: t('export'),
          onClick: handleClickExport,
          disabled: isUpdatingDataset,
          hide: false,
        },
        {
          icon: BiRefresh,
          text: t('refresh'),
          onClick: reloadDataWithConfirmation,
          disabled: isUpdatingDataset,
          hide: false,
        },
      ],
      [
        {
          icon: MdAdd,
          text: t('add_row'),
          onClick: addRow,
          disabled: isUpdatingDataset,
          hide: isTopsDownEditableOutputDataset || inlineEditsDisabled,
        },
        {
          icon: FaRegClone,
          text: t('clone_rows'),
          onClick: handleCloneRows,
          disabled: isUpdatingDataset,
          hide: isTopsDownEditableOutputDataset || inlineEditsDisabled,
        },
        {
          icon: BsFillArchiveFill,
          text: t('delete_rows'),
          onClick: handleDeleteRows,
          disabled: isUpdatingDataset,
          hide: isTopsDownEditableOutputDataset || inlineEditsDisabled,
        },
      ],
      [
        {
          icon: AiOutlineFullscreen,
          text: t('expand'),
          onClick: expandDatasetView,
          disabled: isUpdatingDataset,
          hide: false,
        },
      ],
    ];
  }, [
    currentDataset,
    t,
    isUpdatingDataset,
    inlineEditsDisabled,
    tableData,
    queryClient,
    batchId,
    isHasUnsavedEdits,
    batchMetadata?.batchId,
    triggerImportBaseline,
    triggerLunaExport,
  ]);

  const containerDescription = useMemo(() => {
    const datasetErrorInfo = getDatasetErrorInfo(batchMetadata, currentDataset);

    if (datasetErrorInfo.message) {
      return (
        <Button
          variant="inline-link"
          disabled={!datasetErrorInfo.downloadLink}
          onClick={() => downloadFile(datasetErrorInfo.downloadLink!)}
        >
          {datasetErrorInfo.message}
        </Button>
      );
    }

    const currentDatasetValidationDetails =
      batchMetadata?.datasetStatus?.detailedDatasetStatus?.find(
        (o) => o.datasetName === currentDataset,
      )?.validationDetails;

    const currentDatasetValidationStatus = currentDatasetValidationDetails?.status;

    if (
      currentDatasetValidationStatus &&
      currentDatasetValidationStatus !== ValidationStatusEnumEnum.Success
    ) {
      return (
        <Button
          variant="inline-link"
          disabled={!currentDatasetValidationDetails.messageLocation}
          onClick={() => {
            downloadFile(currentDatasetValidationDetails.messageLocation!);
          }}
        >
          {currentDatasetValidationDetails.message}
        </Button>
      );
    }

    return <span>{description}</span>;
  }, [batchMetadata, currentDataset, description]);

  const createFileName = (datasetName: string, planName: string) =>
    `${datasetName}_${planName.replaceAll('.', '_')}_${currentDateTime()}`;

  const planetDefinitionTableDefaultExportParams: BaseExportParams = {
    allColumns: !featureFlags?.feUseColumnMappingSchemaConfig,
    fileName: createFileName(currentDataset, batchMetadata?.batchName ?? ''),
    exportedRows:
      batchMetadata?.costType === PlanTypeId.FIXED_COST_CONSOLIDATION ? 'filteredAndSorted' : 'all',
    processHeaderCallback: (params: ProcessHeaderForExportParams) => {
      const field = params?.column?.getColDef?.().field;
      if (featureFlags?.feUseColumnMappingSchemaConfig && field && mappingSchema) {
        const mappingConfig = mappingSchema.find((config) => config.originalName === field);
        return mappingConfig?.displayName || field;
      }
      return field ?? '';
    },
  };

  const planetDownloadTemplateFileDefaultExportParams: BaseExportParams = {
    allColumns: !featureFlags?.feUseColumnMappingSchemaConfig,
    fileName: currentDataset + '_template',
    exportedRows: 'all',
    processHeaderCallback: (params: ProcessHeaderForExportParams) => {
      const field = params?.column?.getColDef?.().field;
      if (featureFlags?.feUseColumnMappingSchemaConfig && field && mappingSchema) {
        const mappingConfig = mappingSchema.find((config) => config.originalName === field);
        return mappingConfig?.displayName || field;
      }
      return field ?? '';
    },
    shouldRowBeSkipped: (_params: any) => true,
  };

  const planetDefinitionTableExportParams = isImportModalVisible
    ? planetDownloadTemplateFileDefaultExportParams
    : planetDefinitionTableDefaultExportParams;

  const rowData = useMemo(() => {
    if (validDatasetLocation) return tableData?.rowData;
    if (planDatasetIsFetching || businessRulesIsFetching) return null;
    return [];
  }, [validDatasetLocation, tableData?.rowData, planDatasetIsFetching, businessRulesIsFetching]);

  /** ask for confirmation when user try to leave page with unsaved edits [block unload event] */
  useOnBeforeUnload(isHasUnsavedEdits);

  const blocker = useBlocker(isHasUnsavedEdits);

  /** ask for confirmation when user try to leave page with unsaved edits [block react-router] */
  useEffect(() => {
    if (blocker.state === 'blocked') {
      setConfirmModalInfo({
        header: t('leave_page'),
        message: t('leave_page_unsaved_changes_warning'),
        onProceed: () => {
          setInlineEdits([]);
          /** wait until next render so isHasUnsavedEdits can be updated */
          setTimeout(() => blocker.proceed(), 0);
        },
        onCancel: () => {
          blocker.reset();
        },
      });
    }
  }, [blocker, t]);

  return (
    <>
      <DatasetGridContainer
        currentDataset={currentDataset}
        onDatasetSelected={handleChangeSelectWithConfirmation}
        datasetOptions={datasetOptions}
        headerText={headerText}
        description={containerDescription}
        actionButtonGroups={actionButtonGroups}
      >
        {FilterComponent}
        <AgRowValidationGrid
          isFullScreen={isFullScreen}
          defaultExcelExportParams={planetDefinitionTableExportParams}
          defaultCsvExportParams={planetDefinitionTableExportParams}
          setIsFullScreen={setIsFullScreen}
          validationRowTransformer={tableData?.validationRowTransformer}
          rowData={rowData}
          gridRef={gridRef}
          columnDefs={columnDefs ?? []}
          validHeaders={tableData?.validHeaders ?? []}
          setInlineEdits={setInlineEdits}
          getNewRowId={getNewRowId}
          isColumnEditable={isColumnEditable}
          isShowingFilterComponent={!!FilterComponent}
          savedUnsolvedErrorRowIds={savedUnsolvedErrorRowIds}
          setSavedUnsolvedErrorRowIds={setSavedUnsolvedErrorRowIds}
        />
      </DatasetGridContainer>

      <UploadFileModal
        constraint={t('file_select_same_columns_constraint')}
        title={`${t('import')}: ${currentDatasetLabel}`}
        visible={isImportModalVisible}
        loading={isUpdatingDataset}
        onClose={() => {
          setIsImportModalVisible(false);
        }}
        submitText={t('import')}
        onSubmit={handleUploadEdits}
        datasetName={currentDataset}
        additionalModalContent={
          <SpaceBetween size="xl">
            <Alert type="warning" statusIconAriaLabel="Warning">
              {fileUploadOperation === OperationType.UPSERT
                ? t('import_upsert_dataset_warning')
                : t('import_replace_dataset_warning')}
            </Alert>

            <Button onClick={handleClickExport}>{t('download_template')}</Button>
          </SpaceBetween>
        }
        batchMetadata={batchMetadata}
      />

      <UserConfirmModal
        confirmModalInfo={confirmModalInfo}
        setConfirmModalInfo={setConfirmModalInfo}
      />
    </>
  );
};

export default PlanInputRowValidationGrid;
