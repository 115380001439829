import { StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { Dispatch, RefObject, SetStateAction, useMemo, useRef } from 'react';
import {
  ColDef,
  EditableCallback,
  NewValueParams,
  ValueGetterFunc,
  ValueSetterFunc,
} from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { AgGridBaseWrapper } from 'src/common/ag-grid/AgGridBaseWrapper';
import { agRowInlineEditsUpdate } from 'src/common/ag-grid/row-validation-grid/AgRowValidationEditHelpers';
import {
  IRowValidationCellData,
  IRowValidationRowWithId,
  IValidationRowTransformer,
  isErrorRow,
  navigateToNextErrorRowIds,
} from 'src/common/ag-grid/row-validation-grid/AgRowValidators';
import { useDarkModeContext } from 'src/hooks/useDarkModeContext';
import { AG_INPUT_PAGINATION_PAGE_SIZE } from 'src/pages/plan-manager-page/plan-input-tab/planInputConfig';
import { useAgGridResizeColumns } from 'src/common/ag-grid/useAgGridResizeColumns';
import { useAgGridCalculatedHeight } from 'src/common/ag-grid/useAgGridCalculatedHeight';
import { useAgGridPivotModeConfig } from 'src/common/ag-grid/useAgGridPivotModeConfig';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

interface IAgRowValidationGrid {
  validationRowTransformer?: IValidationRowTransformer;
  rowData: any;
  gridRef: RefObject<AgGridReact>;
  columnDefs: ColDef[];
  isFullScreen: boolean;
  setIsFullScreen: any;
  defaultExcelExportParams: any;
  defaultCsvExportParams: any;
  validHeaders: string[];
  setInlineEdits: any;
  getNewRowId: () => number;
  isColumnEditable: boolean | EditableCallback<any>;
  isShowingFilterComponent: boolean;
  savedUnsolvedErrorRowIds: Set<number>;
  setSavedUnsolvedErrorRowIds: Dispatch<SetStateAction<Set<number>>>;
}

const AgRowValidationGrid = ({
  validationRowTransformer,
  rowData,
  gridRef,
  columnDefs,
  isFullScreen,
  setIsFullScreen,
  defaultExcelExportParams,
  defaultCsvExportParams,
  validHeaders,
  setInlineEdits,
  getNewRowId,
  isColumnEditable,
  isShowingFilterComponent,
  savedUnsolvedErrorRowIds,
  setSavedUnsolvedErrorRowIds,
}: IAgRowValidationGrid) => {
  const { data: featureFlags } = useFeatureFlags();

  const containerRef = useRef<HTMLDivElement>(null);

  const { isDarkMode } = useDarkModeContext();

  const genericValueSetter: ValueSetterFunc<
    Record<string, IRowValidationCellData>,
    string | number
  > = (params) => {
    const data = params.data;
    const { field } = params.colDef;

    const newValue = (() => {
      switch (typeof params.newValue) {
        case 'string':
          return params.newValue.trim() || null;
        case 'number':
          return params.newValue;
        default:
          return null;
      }
    })();

    if (params.oldValue === newValue || !field) return false;

    const newData: IRowValidationCellData = {
      value: newValue,
      original: data[field].original,
      lastValidationSuccess: true,
      ...(featureFlags?.usingValidationFramework && {
        lastValidationNoWarning: true,
      }),
      validationErrors: [],
    };

    data[field] = newData;

    params.api.applyTransaction({ update: [data] });

    return true;
  };

  const genericValueGetter: ValueGetterFunc<
    Record<string, IRowValidationCellData>,
    string | number
  > = (params) => {
    const { field } = params.colDef;
    if (!field) return null;
    return params.data?.[field]?.value ?? null;
  };

  const genericOnCellValueChanged = (params: NewValueParams) => {
    const data: IRowValidationRowWithId = params.data;

    if (!validationRowTransformer) return;

    const oldData = cloneDeep(data);
    if (params?.colDef?.field ?? '' in oldData) {
      oldData[params.colDef.field!].value = params.oldValue;
    }

    const formattedRow = validationRowTransformer(data);

    const inlineEditsUpdater = agRowInlineEditsUpdate(formattedRow, validHeaders, oldData);
    setInlineEdits(inlineEditsUpdater);

    params.api.applyTransaction({ update: [formattedRow] });
    params.api.refreshCells({ rowNodes: [params.node!], force: true });

    /** if this cell value change cleared a saved & unsolved error row */
    if (savedUnsolvedErrorRowIds.has(formattedRow.id.value) && !isErrorRow(formattedRow)) {
      /** update savedUnsolvedErrorRowIds */
      const newErrorRowIds = new Set(savedUnsolvedErrorRowIds);
      newErrorRowIds.delete(formattedRow.id.value);
      setSavedUnsolvedErrorRowIds(newErrorRowIds);

      /** navigate to the next error row */
      navigateToNextErrorRowIds(gridRef, newErrorRowIds);
    }
  };

  const genericCellStyle = (params: any) => {
    const data = params.data?.[params.colDef.field];

    if (!data) return { backgroundColor: 'transparent' };

    if (!data.lastValidationSuccess) {
      return { backgroundColor: isDarkMode ? 'purple' : 'mediumpurple' };
    }

    if (featureFlags?.usingValidationFramework && !data.lastValidationNoWarning) {
      return { backgroundColor: isDarkMode ? 'darkorange' : 'orange' };
    }

    if (data.value !== data.original) {
      return { backgroundColor: isDarkMode ? 'darkgray' : 'lightyellow' };
    }

    return { backgroundColor: 'transparent' };
  };

  const MyCustomTooltip = (params: any) => {
    const data = params.data?.[params.column?.colDef?.field];
    const messageType = !data?.lastValidationSuccess ? 'error' : 'warning';

    return (
      <div
        style={{
          backgroundColor: messageType === 'error' ? 'black' : 'beige',
          padding: '8px',
          border: '1px solid lightgray',
        }}
      >
        {params.value.map((message: string) => (
          <div key={message} style={{ padding: '2px' }}>
            <StatusIndicator type={messageType}>{message}</StatusIndicator>
          </div>
        ))}
      </div>
    );
  };

  const defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    editable: isColumnEditable,
    filter: true,
    onCellValueChanged: genericOnCellValueChanged,
    valueGetter: genericValueGetter,
    valueSetter: genericValueSetter,
    cellStyle: genericCellStyle,
    tooltipComponent: MyCustomTooltip,
    tooltipValueGetter: (params) => {
      try {
        const data = params.data[(params.colDef as ColDef).field!];
        if (
          !data.lastValidationSuccess ||
          (featureFlags?.usingValidationFramework && !data.lastValidationNoWarning)
        ) {
          return data.validationErrors;
        }
      } catch (_) {
        // do nothing
      }
      return '';
    },
  };

  const { resizeColumns } = useAgGridResizeColumns(gridRef, containerRef, rowData);

  const { gridHeight } = useAgGridCalculatedHeight(containerRef, 65, isShowingFilterComponent);

  const {
    onColumnRowGroupChanged,
    onColumnPivotChanged,
    onColumnValueChanged,
    onColumnPivotModeChanged,
  } = useAgGridPivotModeConfig();

  const getRowId = useMemo(
    () => (params: any) => String(params?.data?.id?.value ?? getNewRowId()),
    [getNewRowId],
  );

  return (
    <div ref={containerRef}>
      <AgGridBaseWrapper
        gridRef={gridRef}
        rowBuffer={10}
        tooltipShowDelay={1000}
        suppressColumnVirtualisation={true}
        onFirstDataRendered={resizeColumns}
        onGridSizeChanged={resizeColumns}
        onRowDataUpdated={resizeColumns}
        onColumnRowGroupChanged={onColumnRowGroupChanged}
        onColumnPivotChanged={onColumnPivotChanged}
        onColumnValueChanged={onColumnValueChanged}
        onColumnPivotModeChanged={onColumnPivotModeChanged}
        enableRangeSelection={true}
        enableRangeHandle={true}
        gridHeight={gridHeight}
        isFullScreen={isFullScreen}
        setFullScreen={setIsFullScreen}
        pagination={true}
        paginationPageSize={AG_INPUT_PAGINATION_PAGE_SIZE}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        animateRows={true}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultExcelExportParams={defaultExcelExportParams}
        defaultCsvExportParams={defaultCsvExportParams}
        getRowId={getRowId}
        suppressRowClickSelection={true}
      />
    </div>
  );
};

export default AgRowValidationGrid;
