import { createContext, startTransition, useCallback, useContext, useMemo, useState } from 'react';
import {
  AppLayout,
  Box,
  ContentLayout,
  Header,
  SpaceBetween,
  Tabs,
} from '@amzn/awsui-components-react/polaris';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { Breadcrumbs } from 'src/common/Breadcrumbs';
import { getPlanManagerPageRoute } from 'src/routes';
import { useComputationPollingManager } from 'src/pages/commons/compute-helpers/useComputationPollingManager';
import { ComputeButton } from 'src/pages/commons/compute-helpers/ComputeButton';
import { TabsChangeEvent } from 'src/common/EventType';
import { GlobalNotifications } from 'src/common/GlobalNotifications';
import PlanActionsButtonDropdown from 'src/pages/commons/plan-actions/PlanActionsButtonDropdown';
import {
  PlanTypeId,
  OTR_IB_PLAN_TYPES,
  SupportedDatasetType,
  OTR_OB_PLAN_TYPES,
} from 'src/utils/planning/planetModel';
import { appLayoutHeaderSelector } from 'src/common/NavBar';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

const BASELINE_ENABLED_PLAN_TYPES: string[] = [
  PlanTypeId.DEPRECIATION,
  PlanTypeId.REAL_ESTATE,
  PlanTypeId.UTR_COST_HC,
  PlanTypeId.FIXED_COST_CONSOLIDATION,
];

class PlanStateContextType {
  isHasUnsavedEdits = false;
  setIsHasUnsavedEdits: (value: boolean) => void = () => {};
  trackingAsyncUploadDatasets: Set<SupportedDatasetType> = new Set();
  setTrackingAsyncUploadDatasets: (value: Set<SupportedDatasetType>) => void = () => {};
}

/** plan-level context initialized when entering a plan */
const PlanStateContext = createContext(new PlanStateContextType());

export const usePlanStateContext = () => useContext(PlanStateContext);

const PlanManagerPageContent = () => {
  const { batchId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const activeTabId = location.pathname.split('/').pop();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { data: featureFlags } = useFeatureFlags();

  const { pollingNotifications } = useComputationPollingManager(batchId);

  const additionalNotifications = useMemo<FlashbarProps.MessageDefinition[]>(
    () =>
      pollingNotifications.map((o) => ({
        type: 'in-progress',
        dismissible: false,
        loading: true,
        content: o.content,
      })),
    [pollingNotifications],
  );

  const showBaseline = useMemo(
    () => BASELINE_ENABLED_PLAN_TYPES.includes(batchMetadata?.costType ?? ''),
    [batchMetadata?.costType],
  );

  const hidePlanOutput = useMemo(
    () => OTR_IB_PLAN_TYPES.has(batchMetadata?.costType as PlanTypeId),
    [batchMetadata?.costType],
  );

  const hideAnalysisTab = useMemo(
    () => batchMetadata?.costType === PlanTypeId.CF_CONSOLIDATION,
    [batchMetadata?.costType],
  );

  const showProcessFlow = useMemo(
    () =>
      featureFlags?.feProcessFlowTab &&
      OTR_OB_PLAN_TYPES.has(batchMetadata?.costType as PlanTypeId),
    [batchMetadata?.costType, featureFlags?.feProcessFlowTab],
  );

  // prettier-ignore
  const tabs = useMemo(
    () => [
      { label: t('plan_details_tab_label'), id: 'details', href: `${getPlanManagerPageRoute(batchId ?? '')}/details` },
      ...(showProcessFlow? [{ label: t('plan_process_flow_tab_label'), id: 'process', href: `${getPlanManagerPageRoute(batchId ?? '')}/process-flow` }]:[]),
      ...(showBaseline ? [{ label: t('plan_baseline_tab_label'), id: 'baseline', href: `${getPlanManagerPageRoute(batchId ?? '')}/baseline` }] : []),
      { label: batchMetadata?.costType === PlanTypeId.FIXED_COST_CONSOLIDATION
            ? t('non_designated_input_tab_label')
            : t('plan_input_tab_label'),
        id: 'input', href: `${getPlanManagerPageRoute(batchId ?? '')}/input` },
      ...(hidePlanOutput ? [] : [{ label: t('plan_output_label'), id: 'output', href: `${getPlanManagerPageRoute(batchId ?? '')}/output` }]),
      ...(hideAnalysisTab ? [] : [{ label: t('plan_analysis_tab_label'), id: 'analysis', href: `${getPlanManagerPageRoute(batchId ?? '')}/analysis` }]),
    ],
    [t, batchId, showProcessFlow, showBaseline, hidePlanOutput, hideAnalysisTab, batchMetadata?.costType],
  );

  const handleTabChange = useCallback(
    (e: TabsChangeEvent) => {
      startTransition(() => navigate(e.detail.activeTabHref!));
    },
    [navigate],
  );

  return (
    <AppLayout
      content={
        <ContentLayout
          disableOverlap={true}
          header={
            <Box margin={{ top: 's', bottom: 'xs' }}>
              <Header
                variant="h1"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <PlanActionsButtonDropdown batchId={batchMetadata?.batchId ?? undefined} />
                    <ComputeButton batchId={batchId} />
                  </SpaceBetween>
                }
              >
                Plan: {batchMetadata?.batchName}
              </Header>
            </Box>
          }
        >
          <Tabs
            disableContentPaddings={true}
            tabs={tabs}
            onChange={handleTabChange}
            activeTabId={activeTabId}
          />
          <Outlet />
        </ContentLayout>
      }
      toolsHide={true}
      navigationHide={true}
      breadcrumbs={<Breadcrumbs />}
      notifications={<GlobalNotifications {...{ additionalNotifications }} />}
      stickyNotifications={true}
      contentType="default"
      maxContentWidth={Number.MAX_VALUE}
      disableContentPaddings={false}
      headerSelector={appLayoutHeaderSelector}
    />
  );
};

const PlanManagerPage = () => {
  /** whether the plan has unsaved edits */
  const [isHasUnsavedEdits, setIsHasUnsavedEdits] = useState(false);

  /** track asyncUploadEditsStatus for specific datasets */
  const [trackingAsyncUploadDatasets, setTrackingAsyncUploadDatasets] = useState<
    Set<SupportedDatasetType>
  >(new Set());

  return (
    <PlanStateContext.Provider
      value={{
        isHasUnsavedEdits,
        setIsHasUnsavedEdits,
        trackingAsyncUploadDatasets,
        setTrackingAsyncUploadDatasets,
      }}
    >
      <PlanManagerPageContent />
    </PlanStateContext.Provider>
  );
};

export default PlanManagerPage;
