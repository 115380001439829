import { FeatureTriggerTypeEnum, UpdateBatchResponse } from '@amzn/fox-den-cost-planning-lambda';
import { useQueryClient } from '@tanstack/react-query';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import {
  MutationHookOptions,
  MutationNotificationsMeta,
  QUERY_KEYS,
  useMutationWithNotifications,
} from 'src/api/api-utils';
import { ListBatchesOptionsKeyFilters } from 'src/api/query/useBatchesList';
import { useUserAlias } from 'src/hooks/useUserAlias';
import {
  IUpdateBatchMetadataParsed,
  stringifyBatchMetadataFields,
} from 'src/utils/planning/batchMetadataModel';

export const useLockBatchMutation = (
  options: MutationHookOptions<IUpdateBatchMetadataParsed, UpdateBatchResponse> &
    MutationNotificationsMeta,
) => {
  const queryClient = useQueryClient();
  const { alias } = useUserAlias();

  return useMutationWithNotifications({
    ...options,
    mutationFn: (input) => {
      const stringifiedBatchMetadata = stringifyBatchMetadataFields(input);

      // TODO: call lockBatch API once it's available in backend
      return FoxPlanningApi.updateBatch({
        ...stringifiedBatchMetadata,
        requesterIdentity: alias,
        trigger: FeatureTriggerTypeEnum.Lock,
      });
    },
    onSettled(data, error, variables, context) {
      if (!options.onSettled) {
        const keyFilters: ListBatchesOptionsKeyFilters = {
          costType: [variables.costType ?? ''],
          businessGroup: [variables.businessGroup ?? ''],
          subGroup: [variables.subGroup ?? ''],
          region: [variables.region ?? ''],
        };
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BATCH_METADATA, variables.batchId] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BATCH_LIST, keyFilters] });
      }
      options?.onSettled?.(data, error, variables, context);
    },
  });
};
