import {
  UploadBatchEditsRequest,
  UploadBatchEditsResponse,
} from '@amzn/fox-den-cost-planning-lambda';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FoxPlanningApi } from 'src/api/foxPlanningApi';
import { MutationHookOptions, QUERY_KEYS, useMutationWithNotifications } from 'src/api/api-utils';
import { useUserAlias } from 'src/hooks/useUserAlias';

export enum OperationType {
  UPSERT = 'UPSERT',
  DELETE = 'DELETE',
  REPLACE = 'REPLACE',
}

export const useUploadEditsMutation = (
  options: MutationHookOptions<UploadBatchEditsRequest, UploadBatchEditsResponse> = {},
) => {
  const queryClient = useQueryClient();
  const { alias } = useUserAlias();
  const { t } = useTranslation();

  return useMutationWithNotifications({
    ...options,
    meta: {
      inProgressNotificationText: t('api_in_progress_upload_edits'),
      errorNotificationText: t('api_error_upload_edits'),
      successNotificationText: t('api_success_upload_edits'),
    },
    mutationFn: (input) => {
      /** make sure we send all inline edits as string format because BE won't handle number format correctly */
      const batchEdits = input.batchEdits?.map((edit) => ({
        ...edit,
        current: edit.current?.map((o) => `${o}`),
        previous: edit.previous?.map((o) => `${o}`),
      }));

      return FoxPlanningApi.uploadBatchEdits({
        ...input,
        batchEdits,
        batchVersion: '0',
        requesterIdentity: alias,
        operation: input.operation ?? OperationType.UPSERT,
      });
    },
    onSettled(data, error, variables, context) {
      if (!options.onSettled) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.PLAN_DATASET, variables.batchId, variables.datasetType],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.BATCH_METADATA, variables.batchId],
        });
      }
      options?.onSettled?.(data, error, variables, context);
    },
  });
};
