import { ICellEditorParams } from 'ag-grid-enterprise';
import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import MultiTreeSelect from 'src/common/ag-grid/editors/ag-tree-select/MultiTreeSelect';
import { ISingleTreeDataSource } from 'src/common/ag-grid/editors/ag-tree-select/SingleTreeSelect';

const MULTISELECT_DELIMITER = ',';

const AgMultiTreeSelectFunc = (props: ICellEditorParams | any) => {
  const { value, onValueChange, disabled, treeDataSource, node, column, dropDownOptions } = props;

  const uniqueTreeDataSource: ISingleTreeDataSource[] = useMemo(
    () => uniqBy(treeDataSource, 'id') as any,
    [treeDataSource],
  );

  const selectedOptions = useMemo(() => {
    if (typeof value !== 'string') return value || [];
    return value.split(MULTISELECT_DELIMITER);
  }, [value]);

  return (
    <div
      id="DropdownContainer"
      style={{ height: node.rowHeight + 'px', width: column.actualWidth + 'px' }}
    >
      <MultiTreeSelect
        selectedOptions={selectedOptions}
        setSelectedOptions={onValueChange}
        disabled={disabled}
        treeDataSource={uniqueTreeDataSource}
        dropDownOptions={{ container: '#DropdownContainer', ...dropDownOptions }}
        selectAllUnselectsAll={props.selectAllUnselectsAll}
      />
    </div>
  );
};

export default AgMultiTreeSelectFunc;
