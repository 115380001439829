import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { GetDatasetOptions, GetSubTabs } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import { getSchemaForDataset, IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import {
  DATASET_TYPE_LABEL_MAP,
  SupportedDatasetType,
  PlanTypeId,
  RegionId,
  OTR_PLAN_TYPES,
} from 'src/utils/planning/planetModel';

export enum PlanInputSubTab {
  REFERENCE_TABLES = 'REFERENCE_TABLES',
  DRIVERS = 'DRIVERS',
  OPTIONAL_TABLES = 'OPTIONAL_TABLES', // TODO: to be removed
  OPTIONAL_TABLES_UTR = 'OPTIONAL_TABLES_UTR',
  RATES_HOURS_DRIVERS = 'RATES_HOURS_DRIVERS',
  HIRES_RELEASE_DRIVERS = 'HIRES_RELEASE_DRIVERS',
  COST_DRIVERS = 'COST_DRIVERS', // TODO: to be removed
  HC_DRIVERS = 'HC_DRIVERS', // TODO: to be removed
  MANDATORY_INPUT = 'MANDATORY_INPUT',
  OPTIONAL_INPUT = 'OPTIONAL_INPUT',
  COSTS_INPUT = 'COSTS_INPUT',
  METRICS_INPUT = 'METRICS_INPUT',
  INTERMEDIATE_VIEWS = 'INTERMEDIATE_VIEWS',
}

export interface DatasetInfo {
  id: SupportedDatasetType;
  label: string;
}

export interface SubTabConfig<T> {
  id: T;
  datasets: SupportedDatasetType[];
}

export interface SubTabDetail {
  label: string;
  descriptionDiv: ReactNode;
}

export interface SubTabInfo<T> extends SubTabDetail {
  id: T;
  datasets: DatasetInfo[];
}

export const getInputSubTabs: GetSubTabs<PlanInputSubTab> = (batchMetadata, t) => {
  if (!batchMetadata?.costType) return null;

  const { costType, region, datasetStatus } = batchMetadata;

  const planTypeSubTabMap: Record<PlanTypeId, SubTabConfig<PlanInputSubTab>[]> = {
    [PlanTypeId.CAPEX]: [
      {
        id: PlanInputSubTab.REFERENCE_TABLES,
        datasets: [
          SupportedDatasetType.CAPEX_ACCOUNT_MAPPING_ACTUAL,
          SupportedDatasetType.CAPEX_ACCOUNT_MAPPING_PLAN,
          SupportedDatasetType.CAPEX_PHASING_CURVES,
          SupportedDatasetType.CAPEX_ACCRUAL_OFFSET,
        ],
      },
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [
          SupportedDatasetType.CAPEX_INITIATIVES,
          SupportedDatasetType.CAPEX_NEW_SITES,
          SupportedDatasetType.CAPEX_MOVING_ASSET,
          SupportedDatasetType.CAPEX_MOVING_ASSET_ACTUALIZE,
          SupportedDatasetType.CAPEX_MANUAL_ADJUSTMENTS,
        ],
      },
    ],
    [PlanTypeId.DEPRECIATION]: [
      {
        id: PlanInputSubTab.REFERENCE_TABLES,
        datasets: [
          SupportedDatasetType.DEPRECIATION_LOCATIONS,
          SupportedDatasetType.DEPRECIATION_LEDGER_PERIOD,
          SupportedDatasetType.DEPRECIATION_CAR_ID_MAPPING_AMZL,
        ],
      },
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [
          SupportedDatasetType.DEPRECIATION_MANUAL_ADJUSTMENTS,
          SupportedDatasetType.DEPRECIATION_MANUAL_CAPEX,
        ],
      },
    ],
    [PlanTypeId.REAL_ESTATE]: [
      {
        id: PlanInputSubTab.REFERENCE_TABLES,
        datasets: [
          SupportedDatasetType.REAL_ESTATE_CPI,
          SupportedDatasetType.REAL_ESTATE_CLOSING_SITES,
          SupportedDatasetType.REAL_ESTATE_LAUNCH_MONTHS,
          SupportedDatasetType.REAL_ESTATE_NA_SA_FILE,
          SupportedDatasetType.REAL_ESTATE_EU_SA_FILE,
          SupportedDatasetType.REAL_ESTATE_COLOCATION,
          SupportedDatasetType.REAL_ESTATE_OTHER_SITES,
        ],
      },
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [
          SupportedDatasetType.REAL_ESTATE_PARKRENT,
          SupportedDatasetType.REAL_ESTATE_MANUAL_ADJ,
          SupportedDatasetType.REAL_ESTATE_PARAMETER_ADJ,
        ],
      },
    ],
    [PlanTypeId.JANITORIAL]: [
      {
        id: PlanInputSubTab.OPTIONAL_TABLES,
        datasets: [
          SupportedDatasetType.JANITORIAL_INPUT_LOCATIONS_NEWSITES,
          SupportedDatasetType.JANITORIAL_INPUT_LOCATIONS_BUILD_TYP_OVRD,
          SupportedDatasetType.JANITORIAL_INPUT_SJU_ADJ_MANUAL,
          SupportedDatasetType.JANITORIAL_INPUT_SUBCAT_MAP_MANUAL,
          SupportedDatasetType.JANITORIAL_INPUT_ADDCOST_MANUAL_NEW,
          SupportedDatasetType.JANITORIAL_INPUT_INFLATION_MANUAL,
          SupportedDatasetType.JANITORIAL_INPUT_ADDHOURS_MANUAL,
          SupportedDatasetType.JANITORIAL_INPUT_RATE_MAN_NEW,
          SupportedDatasetType.JANITORIAL_INPUT_HOURS_MAN_NEW,
        ],
      },
    ],
    [PlanTypeId.SECURITY]: [
      {
        id: PlanInputSubTab.OPTIONAL_TABLES,
        datasets: [
          SupportedDatasetType.SECURITY_INPUT_PLANET_LOCATIONS_NEWSITES,
          SupportedDatasetType.SECURITY_INPUT_PLANET_LOCATIONS_BUILDTYPOVRD,
          SupportedDatasetType.SECURITY_INPUT_PLANET_SECURITY_SPECTER_MAN,
          SupportedDatasetType.SECURITY_INPUT_SJU_ADJ_MANUAL_NEW,
        ],
      },
    ],
    [PlanTypeId.THREE_P_TRANSPORTATION]: [
      {
        id: PlanInputSubTab.REFERENCE_TABLES,
        datasets: [SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_CARSERVICE_MAP_WW],
      },
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [
          SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_TSSOP_CARMIX_EU,
          SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_VOLUME_WW,
          SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_SERVICE_VOL_SPLIT,
          SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_UM_COST_WW,
          SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_CPS_WW,
          SupportedDatasetType.THREE_P_TRANSPORTATION_INPUT_3P_CPS_DRIVER_WW,
        ],
      },
    ],
    [PlanTypeId.OTR_WAREHOUSE_TRANSFER]: [
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [
          SupportedDatasetType.PLANET_OTR_WHT_INPUT_VOLUME,
          SupportedDatasetType.PLANET_OTR_WHT_INPUT_SM_ST_DB_MIX,
          SupportedDatasetType.PLANET_OTR_WHT_INPUT_UNIT_SIZE,
          SupportedDatasetType.PLANET_OTR_WHT_INPUT_FILL_RATE,
          ...(region === RegionId.EU
            ? [SupportedDatasetType.PLANET_OTR_WHT_INPUT_EU_SM_ST_MIX]
            : []),
          SupportedDatasetType.PLANET_OTR_WHT_INPUT_LENGTH_OF_HAUL,
          SupportedDatasetType.PLANET_OTR_WHT_INPUT_RATES,
          SupportedDatasetType.PLANET_OTR_WHT_INPUT_OVERLAY_COST,
        ],
      },
    ],
    [PlanTypeId.OTR_DOMESTIC_INBOUND]: [
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [
          SupportedDatasetType.PLANET_OTR_DIB_INPUT_VOLUME,
          SupportedDatasetType.PLANET_OTR_DIB_INPUT_SM_TYPE_MIX,
          SupportedDatasetType.PLANET_OTR_DIB_INPUT_FILL_RATE,
          SupportedDatasetType.PLANET_OTR_DIB_INPUT_PALLETS_PER_ARN,
          SupportedDatasetType.PLANET_OTR_DIB_INPUT_LBS_PER_SHIPMENT,
          SupportedDatasetType.PLANET_OTR_DIB_INPUT_UNIT_SIZE,
          SupportedDatasetType.PLANET_OTR_DIB_INPUT_LENGTH_OF_HAUL,
          SupportedDatasetType.PLANET_OTR_DIB_INPUT_RATES,
          SupportedDatasetType.PLANET_OTR_DIB_INPUT_OVERLAY_COST,
          ...(region === RegionId.EU
            ? [SupportedDatasetType.PLANET_OTR_DIB_INPUT_COST_OVERRIDE]
            : []),
        ],
      },
    ],
    [PlanTypeId.OTR_FREIGHT_CAPITALIZATION]: [
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [
          SupportedDatasetType.PLANET_OTR_FC_INPUT_TRANS_EXPENSE,
          SupportedDatasetType.PLANET_OTR_FC_INPUT_MANUAL_ADJUSTMENTS,
        ],
      },
    ],
    [PlanTypeId.OTR_SUPPLY_TYPE]: [
      {
        id: PlanInputSubTab.REFERENCE_TABLES,
        datasets:
          region === RegionId.EU
            ? [
                SupportedDatasetType.PLANET_OTR_OB_BLOCK_HOUR_MAPPING,
                SupportedDatasetType.PLANET_OTR_OB_EU_LE_MAPPING,
                SupportedDatasetType.PLANET_OTR_OB_FUEL_CONSUMPTION_MAPPING,
                SupportedDatasetType.PLANET_OTR_OB_CANCELLATION_CPL_MAPPING,
              ]
            : [
                SupportedDatasetType.PLANET_OTR_OB_BLOCK_HOUR_MAPPING,
                SupportedDatasetType.PLANET_OTR_OB_FUEL_CONSUMPTION_MAPPING,
              ],
      },
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [
          SupportedDatasetType.PLANET_OTR_OB_HISTORICAL_LOADS,
          SupportedDatasetType.PLANET_OTR_OB_LDT_ACTUALS,
          SupportedDatasetType.PLANET_OTR_OB_SPEED_ACTUALS,
          SupportedDatasetType.PLANET_OTR_OB_CORRIDOR_ALLOC,
          SupportedDatasetType.PLANET_OTR_OB_PRELIM_LOADS,
          SupportedDatasetType.PLANET_OTR_OB_SUPPLY_STRATEGY_ATTAINMENT,
          SupportedDatasetType.PLANET_OTR_OB_NETWORK_LDT_FORECAST,
          SupportedDatasetType.PLANET_OTR_OB_LDT_OVERRIDE,
          SupportedDatasetType.PLANET_OTR_OB_FIXED_BASE_CPBH,
          SupportedDatasetType.PLANET_OTR_OB_FUEL,
          SupportedDatasetType.PLANET_OTR_OB_NON_AM_BASE_COST,
          SupportedDatasetType.PLANET_OTR_OB_TPA,
          ...(region === RegionId.EU ? [SupportedDatasetType.PLANET_OTR_OB_CANCELLATION_RATE] : []),
          SupportedDatasetType.PLANET_OTR_OB_SURFACE_TRANS_OUTPUT_ADJ,
        ],
      },
      {
        id: PlanInputSubTab.INTERMEDIATE_VIEWS,
        datasets: [
          SupportedDatasetType.PLANET_OTR_OB_VIEW_SUPPLY_STRATEGY_ABS_LOADS,
          SupportedDatasetType.PLANET_OTR_OB_VIEW_LOADS_BY_DISTANCE_BAND,
        ],
      },
    ],
    [PlanTypeId.TOPS_DOWN_FORECAST]: [
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [
          SupportedDatasetType.TOPS_DOWN_FORECAST_COEFFICIENT,
          SupportedDatasetType.TOPS_DOWN_FORECAST_KPI_BASELINE,
          SupportedDatasetType.TOPS_DOWN_FORECAST_CHC,
          SupportedDatasetType.TOPS_DOWN_FORECAST_Q2G_CPU,
          SupportedDatasetType.TOPS_DOWN_FORECAST_VOLUME,
          SupportedDatasetType.TOPS_DOWN_FORECAST_MANUAL_BRIDGE,
        ],
      },
    ],
    [PlanTypeId.FIXED_COST_CONSOLIDATION]: [
      {
        id: PlanInputSubTab.DRIVERS,
        datasets: [SupportedDatasetType.PLANET_FIXED_COST_CONSOLIDATION_NON_DESIGNATED_INPUT],
      },
    ],
    [PlanTypeId.UTR_PRODUCTIVITY]: [
      {
        id: PlanInputSubTab.MANDATORY_INPUT,
        datasets: [
          SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_BASELINE_RATES,
          SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_HOURS_ASSUMPTIONS,
          SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_LEARNING_CURVES,
          SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_OVERLAYS,
          SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_RULE_BASED_HOURS,
        ],
      },
      {
        id: PlanInputSubTab.OPTIONAL_INPUT,
        datasets: [
          SupportedDatasetType.UTR_PRODUCTIVITY_VARIABLE_PLANNED_HIRES_RELEASES_OVERRIDE,
          SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_VOLUME_OVERRIDE,
          SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_STARTING_HEADCOUNT_OVERRIDE,
          SupportedDatasetType.UTR_PRODUCTIVITY_INPUT_HEADCOUNT_ADJUSTMENTS_GUARDRAILS,
        ],
      },
    ],
    [PlanTypeId.UTR_COST_HC]: [
      {
        id: PlanInputSubTab.MANDATORY_INPUT,
        datasets: [
          SupportedDatasetType.PLANET_UTR_VARIABLE_BASE_CPH,
          SupportedDatasetType.PLANET_UTR_VARIABLE_LEAVE_OF_ABSENCE_PERCENTAGE,
          SupportedDatasetType.PLANET_UTR_VARIABLE_NON_HR_COST,
          SupportedDatasetType.PLANET_UTR_VARIABLE_SURCHARGES,
        ],
      },
      {
        id: PlanInputSubTab.OPTIONAL_INPUT,
        datasets: [
          SupportedDatasetType.PLANET_UTR_VAR_COST_COGNOS_MAPP,
          SupportedDatasetType.PLANET_UTR_VARIABLE_HC_EMPSHARE_OVRD,
          SupportedDatasetType.PLANET_UTR_VARIABLE_BASE_RATE_INCREASE,
          SupportedDatasetType.PLANET_UTR_COST_ROSTERSHARE_OVRD,
          SupportedDatasetType.PLANET_UTR_COST_TNASHARE_OVRD,
          SupportedDatasetType.PLANET_UTR_COST_TNA_MAN,
          SupportedDatasetType.PLANET_UTR_COST_INFLATION,
        ],
      },
    ],
    [PlanTypeId.CF_CONSOLIDATION]: [
      {
        id: PlanInputSubTab.COSTS_INPUT,
        datasets: [
          SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_FIXED,
          SupportedDatasetType.CF_CONSOLIDATION_INPUT_FC_VARIABLE,
        ],
      },
      {
        id: PlanInputSubTab.METRICS_INPUT,
        datasets: [SupportedDatasetType.CF_CONSOLIDATION_INPUT_SHIPPED_UNITS],
      },
    ],
  };

  const subTabDetailMap: Record<PlanInputSubTab, SubTabDetail> = {
    [PlanInputSubTab.REFERENCE_TABLES]: {
      label: t('reference_tables_label'),
      descriptionDiv: <Trans t={t} i18nKey="reference_table_description" />,
    },
    [PlanInputSubTab.DRIVERS]: {
      label: t('drivers_table_label'),
      descriptionDiv: <Trans t={t} i18nKey="drivers_table_description" />,
    },
    [PlanInputSubTab.OPTIONAL_TABLES]: {
      label: t('optional_tables_label'),
      descriptionDiv: null,
    },
    [PlanInputSubTab.OPTIONAL_TABLES_UTR]: {
      label: t('optional_tables_label'),
      descriptionDiv: <Trans t={t} i18nKey="optional_table_utr_description" />,
    },
    [PlanInputSubTab.RATES_HOURS_DRIVERS]: {
      label: t('rates_hours_table_label'),
      descriptionDiv: <Trans t={t} i18nKey="rates_hours_drivers_table_description" />,
    },
    [PlanInputSubTab.HIRES_RELEASE_DRIVERS]: {
      label: t('hires_releases_table_label'),
      descriptionDiv: <Trans t={t} i18nKey="hires_release_drivers_table_description" />,
    },
    [PlanInputSubTab.COST_DRIVERS]: {
      label: t('cost_drivers_table_label'),
      descriptionDiv: <Trans t={t} i18nKey="cost_drivers_table_description" />,
    },
    [PlanInputSubTab.HC_DRIVERS]: {
      label: t('hc_drivers_table_label'),
      descriptionDiv: <Trans t={t} i18nKey="hc_drivers_table_description" />,
    },
    [PlanInputSubTab.MANDATORY_INPUT]: {
      label: t('mandatory_input_table_label'),
      descriptionDiv: <Trans t={t} i18nKey="mandatory_input_table_description" />,
    },
    [PlanInputSubTab.OPTIONAL_INPUT]: {
      label: t('optional_input_table_label'),
      descriptionDiv: <Trans t={t} i18nKey="optional_input_table_description" />,
    },
    [PlanInputSubTab.COSTS_INPUT]: {
      label: t('costs_tables_label'),
      descriptionDiv: <Trans t={t} i18nKey="reference_table_description" />,
    },
    [PlanInputSubTab.METRICS_INPUT]: {
      label: t('metrics_tables_label'),
      descriptionDiv: <Trans t={t} i18nKey="reference_table_description" />,
    },
    [PlanInputSubTab.INTERMEDIATE_VIEWS]: {
      label: t('intermediate_views_label'),
      descriptionDiv: <Trans t={t} i18nKey="intermediate_views_description" />,
    },
  };

  const optionalDatasets = new Set(
    datasetStatus?.detailedDatasetStatus?.filter((o) => o.isOptional).map((o) => o.datasetName),
  );

  const formatDatasetLabel = (datasetId: SupportedDatasetType) =>
    OTR_PLAN_TYPES.has(costType as PlanTypeId) && optionalDatasets.has(datasetId)
      ? `${DATASET_TYPE_LABEL_MAP[datasetId]} ${t('optional_dataset_label')}`
      : DATASET_TYPE_LABEL_MAP[datasetId];

  return planTypeSubTabMap[costType as PlanTypeId].map((o) => ({
    ...o,
    ...subTabDetailMap[o.id],
    datasets: o.datasets.map((id) => ({ id, label: formatDatasetLabel(id) })),
  }));
};

export const getInputDatasets = (
  batchMetadata: IBatchMetadataParsed | undefined,
  t: TFunction<'translation', undefined>,
): DatasetInfo[] | null => {
  const inputSubTabs = getInputSubTabs(batchMetadata, t);
  if (!inputSubTabs) return null;
  return inputSubTabs.reduce<DatasetInfo[]>((acc, curr) => [...acc, ...curr.datasets], []);
};

export const getInputDatasetOptions: GetDatasetOptions = (batchMetadata, datasets) => {
  if (!batchMetadata?.costType) return [];
  if (!datasets) return [];

  return datasets.map((dataset) => ({
    label: dataset.label,
    value: dataset.id,
    disabled:
      !getSchemaForDataset(dataset.id, batchMetadata) ||
      !batchMetadata.datasetLocations?.find((o) => o.datasetName === dataset.id),
  }));
};

export const AG_INPUT_PAGINATION_PAGE_SIZE = 50;
