import { ICellEditorParams } from 'ag-grid-community';
import { uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import SingleTreeSelect, {
  ISingleTreeDataSource,
} from 'src/common/ag-grid/editors/ag-tree-select/SingleTreeSelect';

const AgSingleTreeSelectFunc = (props: ICellEditorParams | any) => {
  const { value, disabled, treeDataSource, node, column, largeDropdown } = props;
  const uniqueTreeDataSource: ISingleTreeDataSource[] = useMemo(
    () => uniqBy(treeDataSource, 'id') as any,
    [treeDataSource],
  );
  const [selectedValue, setSelectedValue] = useState(value); //Make transitions from single select to multi-select backward compatible.

  const onChange = (selectedItem: any) => {
    if (selectedItem !== selectedValue) {
      setSelectedValue(selectedItem);
      props.onValueChange(selectedItem);
      setTimeout(() => {
        props.stopEditing();
      }, 10);
    }
  };

  const customizedOnTreeBoxOpened = (e: any) => {
    if (e.name === 'opened' && !e.value) {
      setTimeout(() => {
        props.stopEditing();
      }, 10);
    }
  };

  return (
    <div
      id="DropdownContainer"
      style={{ height: node.rowHeight + 'px', width: column.actualWidth + 'px' }}
    >
      <SingleTreeSelect
        selectedValue={selectedValue}
        onChange={onChange}
        disabled={disabled}
        treeDataSource={uniqueTreeDataSource}
        dropDownOptions={{ container: '#DropdownContainer' }}
        setSelectedValue={setSelectedValue}
        customizedOnTreeBoxOpened={customizedOnTreeBoxOpened}
        isTreeBoxOpenedByDefault={true}
        largeDropdown={largeDropdown}
      />
    </div>
  );
};

export default AgSingleTreeSelectFunc;
