import React, { useEffect, useRef } from 'react';

const AgDatePicker = (props: any) => {
  const { node, column, minDate, value, onValueChange } = props;
  const refInput = useRef(null as any);

  useEffect(() => {
    // focus on the input
    refInput.current.focus();
    refInput.current.select();
  }, []);

  return (
    <div style={{ height: node.rowHeight + 'px', width: column.actualWidth + 'px' }}>
      <input
        ref={refInput}
        value={value}
        onChange={(event) => onValueChange(event.target.value as any)}
        style={{ width: '100%', height: '100%' }}
        className="ag-input"
        type="date"
        min={minDate}
      />
    </div>
  );
};

export default AgDatePicker;
