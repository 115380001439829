import {
  Flashbar,
  ProgressBar,
  Box,
  Button,
  Modal,
  SpaceBetween,
  FileDropzone,
  FileTokenGroup,
} from '@amzn/awsui-components-react/polaris';
import { SourceLocator } from '@amzn/fox-den-cost-planning-lambda';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  S3_COST_PLANNING_BUCKET_WRITE_ONLY,
  S3_PLANET_BUCKET_WRITE_ONLY,
  configStorage,
  configRegion,
  putInS3,
} from 'src/utils/s3';
import { FileInput } from 'src/common/FileInput';
import { useNotificationContext } from 'src/hooks/useNotificationContext';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import { IBatchMetadataParsed } from 'src/utils/planning/batchMetadataModel';
import { PlanTypeId } from 'src/utils/planning/planetModel';

/**
 * Props for UploadFileModal components
 */
interface UploadFileModalProps {
  constraint: string;
  title: string;
  visible: boolean;
  loading: boolean;
  onClose: (flag: boolean) => void;
  submitText: string;
  onSubmit: (sourceLocator: SourceLocator) => void;
  datasetName: string;
  additionalModalContent?: ReactNode;
  batchMetadata: IBatchMetadataParsed | undefined;
}

interface FileDropzoneChangeDetail {
  detail: {
    value: File[];
  };
}

/**
 * Common component for handling file upload
 */
export const UploadFileModal = ({
  constraint,
  title,
  loading,
  visible,
  onClose,
  submitText,
  onSubmit,
  datasetName,
  additionalModalContent,
  batchMetadata,
}: UploadFileModalProps) => {
  // States
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progressPercent, setProgressPercent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const { addNotificationForApiError } = useNotificationContext();

  /**
   * This function will close the modal
   */
  const handleClose = () => {
    setProgressPercent(0);
    setSelectedFile(null);
    onClose(false);
  };

  const { data: featureFlags } = useFeatureFlags();

  /**
   * This function will submit the selected file
   */
  const onClickUpload = async (batchMetadata: IBatchMetadataParsed | undefined) => {
    if (!selectedFile) {
      return;
    }
    try {
      setIsUploading(true);
      // currently only UTR Productivity supports us east bucket
      const bucket =
        featureFlags?.utrUSEastBucket &&
        (batchMetadata?.costType ?? '') === PlanTypeId.UTR_PRODUCTIVITY
          ? S3_PLANET_BUCKET_WRITE_ONLY
          : S3_COST_PLANNING_BUCKET_WRITE_ONLY;

      const fileParts = selectedFile.name.split('.');
      const fileType = fileParts[fileParts.length - 1];

      const s3Path = '';
      configStorage(s3Path, bucket);
      configRegion(bucket);

      const fileName = `${datasetName}/${new Date().getTime()}.${fileType}`;

      await putInS3(fileName, selectedFile, selectedFile.type, (progress: number) => {
        setProgressPercent(progress);
      });

      const fileLocator: SourceLocator = {
        bucket,
        key: s3Path + fileName,
      };

      handleClose();
      await onSubmit(fileLocator);
    } catch (error: any) {
      addNotificationForApiError({ content: t('api_error_upload_file_s3'), error });
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileDrop = ({ detail }: FileDropzoneChangeDetail) => {
    if (!featureFlags?.feDropFile) return;

    const file = detail.value[0];
    setSelectedFile(file);
  };

  /**
   * This function will update selected file
   */
  const handleFileSelect = (file: File | null) => {
    setSelectedFile(file);
  };

  return (
    <Modal
      size="large"
      header={title}
      onDismiss={handleClose}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={handleClose} variant="link">
              {t('cancel')}
            </Button>
            <Button
              onClick={() => onClickUpload(batchMetadata)}
              disabled={!selectedFile}
              loading={loading || isUploading}
              loadingText={t('api_in_updation')}
              variant="primary"
            >
              {submitText}
            </Button>
          </SpaceBetween>
        </Box>
      }
      visible={visible}
    >
      <SpaceBetween size="xl">
        {Boolean(progressPercent) && (
          <Flashbar
            items={[
              {
                type: 'info',
                content: (
                  <ProgressBar
                    label={t('uploading_file_label')}
                    value={progressPercent}
                    variant="flash"
                  />
                ),
              },
            ]}
          />
        )}
        <FileInput
          form={{
            accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            description: t('choose_csv_xlsx_to_upload'),
            label: t('select_file_label'),
            constraint,
          }}
          file={selectedFile}
          onChange={handleFileSelect}
        />

        {featureFlags?.feDropFile && (
          <FileDropzone onChange={handleFileDrop}>{t('drop_file_label')}</FileDropzone>
        )}

        {selectedFile && (
          <FileTokenGroup
            items={[{ file: selectedFile }]}
            onDismiss={() => {
              setSelectedFile(null);
            }}
          />
        )}
        {additionalModalContent}
      </SpaceBetween>
    </Modal>
  );
};
